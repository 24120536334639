module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dierda - Finanzberatung","short_name":"Dierda","start_url":"/","description":"Dein aufklärender und kompetenter Ansprechpartner in Sachen Finanzen.","lang":"de","background_color":"#ffffff","theme_color":"#1939ec","display":"standalone","icon":"src/data/img/app_icon.png","cache_busting_mode":"none","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
